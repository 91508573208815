import {
  TShowroomsResponse,
  TRequestShowroomsCallback,
} from "./interfaces/showrooms";
import API from "../index";

export const getActivePWAShowrooms: TRequestShowroomsCallback = () => {
  return API.get<TShowroomsResponse>(
    "/v3/showrooms?showroom_active_code=1&showroom_active_code_for_application=1"
  ).then(({ data }) => {
    return data;
  });
};
