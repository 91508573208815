import API from "../index";
import {
  ESearchType,
  TClientInfoResponse,
  TClientsResponse,
  TRequestClientInfoCallback,
  TRequestClientsCallback,
} from "./interfaces/clients";

export const getClients: TRequestClientsCallback = ({
  repCode,
  accountCode,
  accountName,
  email,
  authToken,
  searchType = ESearchType.client,
  subsidiaryCode,
}) => {
  return API.get<TClientsResponse>("/v3/clients", {
    params: {
      reps_code: repCode,
      account_code: accountCode,
      account_name: accountName,
      email: email,
      search_type: searchType,
      subsidiary_code: subsidiaryCode,
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then(({ data }) => data);
};

export const fetchAllClients: TRequestClientsCallback = ({
  email,
  authToken,
}) => {
  return API.get<TClientsResponse>("/v3/clients", {
    params: {
      email: email,
    },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }).then(({ data }) => data);
};

export const getClientInfo: TRequestClientInfoCallback = ({
  accountCode,
  authToken,
}) => {
  return API.get<TClientInfoResponse>(
    `/v3/clients/check-information/${accountCode}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  ).then(({ data }) => data);
};
