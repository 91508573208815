import API from "../index";
import {
  TOrderLine,
  TOrderRequest,
  TOrderResponse,
  TRequestOrderCallback,
} from "./interfaces/order";

export const setOrder: TRequestOrderCallback = (
  cartItems,
  address,
  user,
  shippingContactName,
  shippingAddressType,
  billingName,
  phone,
  comment
) => {
  let orderLines: TOrderLine[] = cartItems.map((item) => {
    return {
      product_code: item.product.code,
      small_size: item.product.size === "small" ? "1" : "",
      large_size: item.product.size === "large" ? "1" : "",
      quantity: item.number,
      // unit: "",
      // unit_price: 0,
      // extra_discount_percentage: 0,
      // privilege_discount_percentage: 0,
      // expiry_date: "",
      // price_list: "",
      // VAT_percentage: "",
    };
  });

  const order: TOrderRequest = {
    order_type: "S",
    header: {
      subsidiary_code: user.client!.pierreFreySubsidiary!,
      account_code: user.client!.accountCode!,
      shipping_contact_email: user.client!.email![0],
      rep_code: user.client!.repsCode,
      currency: user.client!.currencyCode,
      billing_name: billingName,
      billing_street_1: user.client!.street,
      billing_street_2: user.client!.streetAddition,
      billing_postal_code: user.client!.postalCode,
      billing_city: user.client!.city,
      billing_state_or_department: user.client!.stateOrDepartment,
      billing_country_code: user.client!.countryCode!,
      billing_country_name: user.client!.countryName,
      shipping_name: user.client!.accountName!,
      shipping_contact_name: shippingContactName,
      shipping_street_1: address.address1,
      shipping_street_2: address.address2,
      shipping_postal_code: address.postalCode,
      shipping_city: address.city,
      shipping_state_or_department: address.stateOrDepartment,
      shipping_country_code: address.countryCode!,
      shipping_country_name: address.countryName,
      shipping_address_type: shippingAddressType,
      phone: phone?.replace(/\s/g, ""),
      // reference: "",
      // specifier: "",
      // project_name: "",
      showroom_code: "000000",
      comment_1: comment?.substring(0, 60),
      comment_2: comment?.substring(60, 120),
      comment_3: comment?.substring(120, 180),
      comment_4: comment?.substring(180, 240),
      comment_5: comment?.substring(240, 300),
      comment_6: comment?.substring(300, 360),
    },
    order_lines: orderLines,
  };
  return API.post<TOrderResponse>(`/v3/order`, order).then(({ data }) => data);
};
