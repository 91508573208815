import API from "../index";
import { TRequestStockCallback, TStockResponse } from "./interfaces/stock";

export const getStock: TRequestStockCallback = (
  productCode,
  accountCode,
  subsidiaryCode
) => {
  return API.get<TStockResponse>(
    `/v3/products/${productCode}/stock?account_code=${accountCode}&subsidiary_code=${subsidiaryCode}`
  ).then(({ data }) => data);
};
